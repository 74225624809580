import * as React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";
import { CaseFragment } from "../../fragments"

import Layout from "../../layouts/default-layout";

import PageHead from "../../components/pageHead";
import GetInTouch from "../../components/getInTouch";
import BodyBlockContent from "../../components/bodyBlockContent";
import TeaserMedia from "../../components/teaserMedia";
import TagList from "../../components/tagList";
import Share from "../../components/share";

import { extractImageFromData } from '../../lib/helpers';

const CaseDetailPage = ({ path, data }) => {
  const intl = useIntl()
  const content = data.sanityCase;
  const title = content.title ? content.title[intl.locale] : '';
  const metaTitle = content.meta_title ? content.meta_title[intl.locale] : title;
  const metaDescription = content.meta_description ? content.meta_description[intl.locale] : content.subline ? content.subline[intl.locale] : null;

  return (
    <Layout textWhite={content.lightHeader} title={metaTitle} description={metaDescription} og_image={extractImageFromData(content, 'poster')} noIndex={content.meta_noindex} path={path}>
      <PageHead
        topline={content.customer && content.customer.customer ? content.customer.customer.title[intl.locale] : null}
        headline={title}
        subline={content.subline && content.subline[intl.locale]}
        backgroundImage={extractImageFromData(content, 'poster')}
        backgroundVideo={content.postervideo}
        textWhite={content.lightHeader}
      />
      
      <div className="mb-16">
        <div className="tro-default-padding-x tro-projects-list-container">
          {/* replace taglist below with own filter component */}
          <TagList
            tags={ content.tags }
          />
        </div>
      </div>
      
      {content._rawBody && (
        <BodyBlockContent data={content._rawBody[intl.locale]} />
      )}
      
      {content.credits && content.credits.length > 0 && (
        <div className="mb-10 tro-default-padding-x">
          <div className="tro-grid-responsive">
            {content.credits.map((el, index) => {
              return (
                <div key={index} className="p-4 mb-6 font-mono text-sm border-l-8 min-h-88px medium:min-h-124px col-span-full medium:col-span-3 bg-brand-lighter border-brand">
                  {el.department && (
                    <span className="text-xs font-bold medium:text-special">{el.department[intl.locale]}</span>
                  )}
                  
                  {el.people.split("\n").map((p, index2) => (
                    <span  key={index2} className="block text-xs medium:text-special">{p}</span>
                  ))}
                  
                </div>
              )
            })}
            
          </div>
        </div>
      )}
      <Share title={title} />
    </Layout>
  );
};

export default CaseDetailPage;

export const query = graphql`
  query case ($id: String) {
    sanityCase(id: { eq: $id }) {
      ... Case
      meta_title {
        de
        en
      }
      meta_description {
        de
        en
      }
      meta_noindex
    }
    allSanityFileAsset {
      nodes {
        id
        url
      }
    }
  }
`
